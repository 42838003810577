import React from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { CatalogMobileType } from '@/typings/model';

function CatalogCard({ image, label, url, isCatalog }: CatalogMobileType) {
  return (
    <Component>
      <Link to={url}>
        <Body>
          <Image
            {...convertThumbnailToPictureProps(image)}
            alt={label ?? ''}
            catalog={isCatalog}
          />
        </Body>
        <Label catalog={isCatalog}>{label}</Label>
      </Link>
    </Component>
  );
}

export default CatalogCard;

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 17px;

  border-radius: 6px;
  background-color: ${colors.grayCart};

  & > a {
    width: 100%;
    height: 100%;
  }
`;

const Body = styled.div`
  margin-top: 16px;
`;

const Image = styled(Picture)`
  width: ${({ catalog }: { catalog: Nullable<boolean> }) =>
    catalog ? '90px' : '64px'};
  height: 64px;
  margin: 0 auto;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Label = styled.div`
  text-align: center;
  font-size: 13px;
  line-height: 40px;
  font-weight: 600;

  color: ${({ catalog }: { catalog?: Nullable<boolean> }) =>
    catalog ? colors.darkRed : colors.black};

  @media (min-width: 768px) {
    font-size: 15px;
  }
`;
