import React from 'react';
import styled from 'styled-components';

import A1 from '@/components/A1';
import Mts from '@/components/Mts';
import { Nullish } from '@tager/web-core';

interface Props {
  reference: {
    a1: Nullish<string>;
    mts: Nullish<string>;
  };
}

function Phones({ reference }: Props) {
  const { a1, mts } = reference;
  return (
    <Component>
      <A1 title={a1} />
      <Mts title={mts} />
    </Component>
  );
}

const Component = styled.div`
  margin-top: 15px;
`;

export default Phones;
