import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { breakpoints, colors } from '@/constants/theme';
import { PaymentCardType } from '@/typings/model';
import Picture from '@/components/Picture';
import Link from '@/components/Link';

function convertUrl(url: string): string {
  return url.replace('http://', '').replace('https://', '').replace('www.', '');
}

function MethodCard({ data }: { data: PaymentCardType }) {
  const url = convertUrl(data?.bankUrl ?? '');

  return (
    <Component>
      {data?.disabledText && (
        <Overlay>
          <CautionText>{data?.disabledText} </CautionText>
        </Overlay>
      )}
      <Content>
        <PictureWrapper>
          <StyledPicture {...convertThumbnailToPictureProps(data.image)} />
        </PictureWrapper>
        <InnerWrapper>
          <Name>{data?.name}</Name>
          <Bank>
            <BankTitle>{data?.bankName + ', '}</BankTitle>
            <Link to={data.bankUrl}>
              <BankUrl>{url}</BankUrl>
            </Link>
          </Bank>
          <Period>{data?.period}</Period>
        </InnerWrapper>
      </Content>
      <Note highlight={data.noteHighlight}>{data?.note}</Note>
    </Component>
  );
}

export default MethodCard;

const Component = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 12px;
  min-height: 340px;
  border: 1px solid ${colors.gray70};

  & > div {
    text-align: center;
  }

  @media (max-width: ${breakpoints.tabletSmall}px) {
    & > div {
      text-align: left;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(245, 245, 245, 0.5);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CautionText = styled.div`
  padding: 15px 20px;
  background-color: ${colors.gray200};
  align-self: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

const Content = styled.div`
  padding: 15px 15px 0;
`;

const StyledPicture = styled(Picture)`
  position: absolute;
  inset: 0;

  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;

const InnerWrapper = styled.div``;

const PictureWrapper = styled.div`
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  max-width: 280px;
  margin: 0 auto;
  padding-top: min(60%, 130px);
`;

const Name = styled.h3`
  margin-top: 17px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;
const Bank = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
`;
const BankTitle = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
`;

const BankUrl = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: ${colors.primary};
`;

const Period = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

const Note = styled.div<{ highlight?: boolean | null }>`
  margin-top: 10px;
  width: 100%;
  padding: 5px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${({ highlight }) =>
    highlight ? `${colors.primary}` : `${colors.gray900}`};
  background-color: ${colors.gray800};
`;
