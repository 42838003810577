import { Nullish } from '@tager/web-core';

import { PageModuleType } from '@/typings/common';
import Home from '@/modules/Home';
import DefaultTemplate from '@/modules/DefaultTemplate';
import Contacts from '@/modules/Contacts';
import FAQ from '@/modules/FAQ';
import Payment from '@/modules/Payment';
import Dostavka from '@/modules/Dostavka';
import Rassrochka from '@/modules/Rassrochka';

const DEFAULT_PAGE_MODULE: PageModuleType = {
  component: DefaultTemplate,
  template: '',
};

const PAGE_MODULE_LIST: Array<PageModuleType> = [
  {
    component: Home,
    template: 'belmebel:home',
  },
  {
    component: Contacts,
    template: 'belmebel:contacts',
  },
  {
    component: FAQ,
    template: 'belmebel:faq',
  },
  {
    component: Payment,
    template: 'belmebel:payment',
  },
  {
    component: Dostavka,
    template: 'belmebel:delivery',
  },
  {
    component: Rassrochka,
    template: 'belmebel:rassrochka',
  },
];

export function getPageModuleByTemplate(
  template: Nullish<string>
): PageModuleType {
  return (
    PAGE_MODULE_LIST.find((pageModule) => pageModule.template === template) ??
    DEFAULT_PAGE_MODULE
  );
}
