import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as Icon } from '@/assets/svg/arrow_question.svg';
import { colors } from '@/constants/theme';
import { StringFieldType } from '@/typings/common';

function Collapse({
  data,
}: {
  data: { question: StringFieldType; answer: StringFieldType };
}) {
  const [isOpen, setOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  const opener = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.scrollHeight);
    }
  }, []);

  return (
    <Item>
      <QuestionCell active={isOpen} onClick={opener}>
        <Question>{data.question}</Question>
        <StyledIcon />
      </QuestionCell>
      <CollapseWrapper>
        <Answer
          ref={ref}
          active={isOpen}
          size={height}
          dangerouslySetInnerHTML={{ __html: data?.answer ?? '' }}
        />
      </CollapseWrapper>
    </Item>
  );
}

export default Collapse;

const Item = styled.div`
  padding: 10px 15px;
`;
const Question = styled.span`
  width: 75%;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;
const QuestionCell = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > svg {
    transition: transform 0.2s ease;
  }

  ${({ active }) =>
    active &&
    css`
      & > span {
        color: ${colors.primary};
      }

      & > svg {
        transform: rotate(-180deg);
      }

      & > svg path {
        fill: ${colors.primary};
      }
    `}
`;
const CollapseWrapper = styled.div`
  overflow: hidden;
`;

const Answer = styled.div<{ active: boolean; size: number }>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;

  transition: height 0.2s ease, margin 0.2s ease;
  ${({ active, size }) =>
    active
      ? css`
          height: ${size}px;
          margin-top: 9px;
        `
      : css`
          height: 0;
          margin-top: 0;
        `}
`;
const StyledIcon = styled(Icon)``;
