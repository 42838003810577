import React from 'react';
import styled from 'styled-components';

import { useCurrentPage } from '@/hooks';
import { RassrochkaPageType } from '@/typings/model';
import BreadCrumb from '@/components/BreadCrumb';
import ContentContainer from '@/components/ContentContainer';
import PaymentMethodsSection from '@/modules/Payment/components/PaymentMethodsSection';
import RassrochkaSection from '@/modules/Payment/components/RassrochkaSection';

import Calculator from './components/Calculator';

function Rassrochka() {
  const page = useCurrentPage<RassrochkaPageType>();

  const breadCrumbList = [];

  if (page) {
    breadCrumbList.push({
      label: page.title,
      url: page.path,
    });
  }

  return (
    <Component>
      <ContentContainer>
        <Top>
          <BreadCrumb breadCrumbs={breadCrumbList} />
          <Title>{page.title}</Title>
          <Body dangerouslySetInnerHTML={{ __html: page?.body ?? '' }} />
        </Top>
      </ContentContainer>
      <Calculator />
      <MethodsWrapper>
        <PaymentMethodsSection />
      </MethodsWrapper>
      <Wrapper>
        <RassrochkaSection title={''} note={''} content={''} />
      </Wrapper>
    </Component>
  );
}

export default Rassrochka;

const Component = styled.div``;
const Top = styled.div``;

const Title = styled.h2`
  margin-top: 8px;
`;
const Body = styled.div`
  margin-top: 5px;

  & > p:not(:first-child) {
    margin-top: 30px;
  }
  & > ul {
    margin-top: 30px;
  }
  & > ul > li:not(:first-child) {
    margin-top: 20px;
  }

  & > ul > li {
    margin-left: 15px;
    list-style: initial;
  }
`;

const Wrapper = styled.div`
  margin-top: 40px;
`;

const MethodsWrapper = styled.div`
  margin-top: 23px;
`;
