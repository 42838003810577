import React from 'react';
import styled from 'styled-components';

import Slider from '@/modules/Home/components/Slider';
import Cards from '@/modules/Home/components/Cards';
import Catalog from '@/modules/Home/components/Catalog';
import TopSells from '@/modules/Home/components/TopSells';
import Arrivals from '@/modules/Home/components/Arrivals';
import Blog from '@/modules/Home/components/Blog';

function Home() {
  return (
    <Component>
      <Slider />
      <Cards />
      <Catalog />
      <TopSells />
      <Arrivals />
      <Blog />
    </Component>
  );
}

const Component = styled.div``;

export default Home;
