import React from 'react';
import styled from 'styled-components';

import { useCurrentPage } from '@/hooks';
import BreadCrumb from '@/components/BreadCrumb';
import { BreadCrumbType } from '@/typings/common';
import ContentContainer from '@/components/ContentContainer';
import Helper from '@/components/Helper';
import { colors } from '@/constants/theme';
import { FaqPageType } from '@/typings/model';

import Collapse from './components/Collapse';

function Faq() {
  const page = useCurrentPage<FaqPageType>();

  const templateField = page?.templateFields;

  const breadCrumb: BreadCrumbType[] = [];

  if (page) {
    breadCrumb.push({
      label: page.title,
      url: page.path,
    });
  }

  return (
    <Component>
      <ContentContainer>
        <Header>
          <BreadCrumb breadCrumbs={breadCrumb} />
          <PageTitle>{page.title}</PageTitle>
        </Header>
      </ContentContainer>
      <Content>
        {(templateField?.sections ?? []).map((section, index) => (
          <QuestionSection key={index}>
            <Title>{section.title}</Title>
            {(section?.questions ?? []).map((question, index) => (
              <Collapse data={question} key={index} />
            ))}
          </QuestionSection>
        ))}
        <Helper />
      </Content>
    </Component>
  );
}

export default Faq;

const Component = styled.div``;
const Header = styled.div``;
const PageTitle = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const Content = styled.div`
  & > div:not(:first-child) {
    margin-top: 27px;
  }
`;
const QuestionSection = styled.div`
  & > div {
    border-top: 1px solid ${colors.gray200};
  }

  & > div:last-child {
    border-bottom: 1px solid ${colors.gray200};
  }
`;
const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 10px 0 14px;
  padding: 0 15px;
`;
