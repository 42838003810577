import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

import ContentContainer from '@/components/ContentContainer';
import DocumentCard from '@/modules/Payment/components/DocumentCard';
import { colors } from '@/constants/theme';
import { PaymentPageType } from '@/typings/model';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { useCurrentPage } from '@/hooks';

SwiperCore.use([Navigation]);

function DocumentSection() {
  const page = useCurrentPage<PaymentPageType>();
  const { templateFields } = page;

  return (
    <DocumentsContainer>
      <ContentContainer>
        <Header>
          <Title>{templateFields?.documentsTitle}</Title>
          <Nav>
            <Prev className="document-card-prev" />
            <Next className="document-card-next" />
          </Nav>
        </Header>
      </ContentContainer>
      {templateFields?.documentsItems && (
        <Swiper
          navigation={{
            prevEl: '.document-card-prev',
            nextEl: '.document-card-next',
          }}
          slidesOffsetBefore={15}
          slidesOffsetAfter={15}
          spaceBetween={15}
          slidesPerView={1.25}
          slidesPerGroup={1}
          breakpoints={{
            500: {
              slidesPerView: 2.2,
            },
          }}
        >
          {templateFields.documentsItems.map((doc, index) => (
            <SwiperSlide key={doc?.caption ?? '' + index}>
              <DocumentCard data={doc} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <DocumentText
        dangerouslySetInnerHTML={{
          __html: templateFields?.documentsText ?? '',
        }}
      />
    </DocumentsContainer>
  );
}

export default DocumentSection;

const DocumentsContainer = styled.div`
  margin-top: 30px;
  padding-bottom: 17px;
  border-bottom: 1px solid ${colors.gray70};
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0 0 46px;
`;

const Prev = styled.div`
  cursor: pointer;
  position: relative;
  height: 22.5px;
  width: 15px;
  &:before {
    position: absolute;
    border-radius: 5px 0 0 5px;
    content: '';
    top: 0;
    right: 0;
    height: 1px;
    width: 16px;
    transform-origin: 100% 0;
    transform: rotate(315deg);
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:after {
    position: absolute;
    border-radius: 5px 0 0 5px;
    content: '';
    bottom: 0;
    right: 0;
    height: 1px;
    width: 15px;
    transform-origin: 100% 0;
    transform: rotate(45deg);
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:hover:before,
  &:hover:after {
    background-color: ${colors.black300};
  }
`;
const Next = styled.div`
  cursor: pointer;
  position: relative;
  height: 22.5px;
  width: 15px;

  &:before {
    cursor: pointer;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 1px;
    width: 16px;
    transform-origin: 0 0;
    transform: rotate(45deg);
    border-radius: 0 5px 5px 0;
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:after {
    cursor: pointer;
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 1px;
    width: 15px;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    border-radius: 0 5px 5px 0;

    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:hover:before,
  &:hover:after {
    background-color: ${colors.black300};
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DocumentText = styled.div`
  margin-top: 15px;
  padding: 0 15px;

  & > p:not(:first-child) {
    margin-top: 20px;
  }
`;
