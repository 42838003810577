import React from 'react';
import styled from 'styled-components';

import { useCurrentPage } from '@/hooks';
import { DefaultHomePageType } from '@/typings/model';
import ContentContainer from '@/components/ContentContainer';
import { breakpoints } from '@/constants/theme';

import CatalogCard from './components/CatalogCard';

function Catalog() {
  const {
    templateFields: { catalogTitle, catalogMobileItems },
  } = useCurrentPage<DefaultHomePageType>();

  return (
    <Component>
      <ContentContainer>
        <Title>{catalogTitle}</Title>
        <Content>
          <List>
            {catalogMobileItems.map((card, index) => (
              <CatalogCard {...card} key={index} />
            ))}
          </List>
        </Content>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.div`
  padding-top: 4px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;

  @media (min-width: ${breakpoints.tabletSmall}) {
    font-size: 24px;
  }
`;

const Content = styled.div``;

const List = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  justify-items: center;
  gap: 10px;
  
  
  }
`;

export default Catalog;
