import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import { ReactComponent as Wallet } from '@/assets/svg/Calculator/wallet.svg';
import { ReactComponent as Bank } from '@/assets/svg/Calculator/bank.svg';
import { ReactComponent as Card } from '@/assets/svg/Calculator/card.svg';
import { normalizePrice } from '@/utils/common';

import { OutputType } from '../Clculator.typings';

type Props = {
  output: OutputType;
};

function Output({ output }: Props) {
  return (
    <Component>
      <Item>
        <Wallet />
        <Content>
          <Label>Итоговая сумма:</Label>
          <Note>{normalizePrice(output.price)} руб.</Note>
        </Content>
      </Item>
      <Item>
        <Bank />
        <Content>
          <Label>Переплата:</Label>
          <Note>{normalizePrice(output.underpayment)} руб.</Note>
        </Content>
      </Item>
      <Item>
        <Card />
        <Content>
          <Label>Ежемесячный платеж:</Label>
          <Note monthly>{normalizePrice(output.monthlyPayment)} руб.</Note>
        </Content>
      </Item>
    </Component>
  );
}

export default Output;

const Component = styled.div`
  max-width: 480px;
  padding: 10px 15px;
  margin-top: 20px;
  background-color: ${colors.primary};
  border-radius: 5px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: ${colors.white};
`;
const Note = styled.div<{ monthly?: boolean }>`
  font-style: normal;
  font-weight: ${({ monthly }) => (monthly ? '600' : 'normal')};
  font-size: 20px;
  line-height: 30px;
  color: ${colors.white};
`;
const Content = styled.div`
  margin-left: 11px;
`;
