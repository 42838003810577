import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';
import { ButtonLink } from '@/components/Button';
import { DeliveryCardType } from '@/typings/model';

interface Props {
  title: string;
  body?: string;
  data?: DeliveryCardType[];
}

function Description({ title, body, data }: Props) {
  return (
    <Component>
      <Header>
        <ContentContainer>
          <Title>{title}</Title>
          <Body dangerouslySetInnerHTML={{ __html: body ?? '' }} />
        </ContentContainer>
      </Header>
      {data &&
        data.map(({ title, prices, text, button }, index) => (
          <Section key={index}>
            <ContentContainer>
              <Title>{title}</Title>
              <PriceSection>
                {!!prices?.length &&
                  prices.map(({ name, price }, index) => (
                    <PriceInner key={index}>
                      <Name>{name}</Name>
                      <Price isChargeFree={price === 'Бесплатно'}>
                        {price}
                      </Price>
                    </PriceInner>
                  ))}
              </PriceSection>
              {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
              {button && (
                <StyledButtonLink
                  variant={'outlined-primary-w100-f13'}
                  href={button.link ?? ''}
                  target={button.isNewTab ? '_blank' : '_self'}
                >
                  {button.label}
                </StyledButtonLink>
              )}
            </ContentContainer>
          </Section>
        ))}
    </Component>
  );
}

export default Description;

const Component = styled.div`
  margin-top: 8px;
`;
const Header = styled.div`
  border-bottom: 1px solid ${colors.gray70};
  padding-bottom: 10px;
`;

const Section = styled.div`
  padding: 15px 0 18px;
  border-bottom: 1px solid ${colors.gray70};
`;

const PriceSection = styled.div`
  & > div {
    margin-top: 15px;
  }
  & > div:first-child {
    margin-top: 5px;
  }
`;

const PriceInner = styled.div``;

const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const Body = styled.div`
  margin-top: 6px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
`;

const Name = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;
const Price = styled.div<{ isChargeFree: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${({ isChargeFree }) =>
    isChargeFree ? `${colors.primary}` : `${colors.black}`};
`;
const Text = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;

  & > p {
    margin-top: 5px;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  margin-top: 25px;
  max-width: 480px;
`;
