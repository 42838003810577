import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Arrow } from '@/assets/svg/arrow_question.svg';
import { colors } from '@/constants/theme';
import { CreditPriceSettingItem } from '@/typings/model';
import { ReactComponent as TimeIcon } from '@/assets/svg/time.svg';

type Props = {
  data: CreditPriceSettingItem[];
  setData: (period: number) => void;
};

function CalculatorSelect({ data, setData }: Props) {
  const [currentPeriodIndex, setIndex] = useState(0);
  const [isOptionsOpen, setOpen] = useState(false);

  const selectHandler = () => {
    setOpen((pre) => !pre);
  };
  const clickHandler = (index: number) => {
    setIndex(index);
    setData(index);
  };

  return (
    <Component onClick={selectHandler}>
      <StyledTime />
      <Option>
        <Label isSelected>{data[currentPeriodIndex]?.period ?? ''} мес.</Label>
        <StyleArrow isOpen={isOptionsOpen} />
      </Option>
      <Collapse isVisible={isOptionsOpen}>
        {!!data.length &&
          data.map(({ period }, index) => (
            <Option key={index} onClick={() => clickHandler(index)}>
              <Label>{period ?? ''} мес.</Label>
            </Option>
          ))}
      </Collapse>
    </Component>
  );
}

export default CalculatorSelect;

const Component = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 4px 15px 4px 40px;
  min-height: 34px;
  cursor: pointer;
  border: 1px solid ${colors.gray70};
  border-radius: 5px;
`;

const StyledTime = styled(TimeIcon)`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
`;

const Label = styled.div<{ isSelected?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${({ isSelected }) =>
    isSelected ? `${colors.primary}` : `${colors.black}`};

  &:hover {
    color: ${colors.primary};
  }
`;
const StyleArrow = styled(Arrow)<{ isOpen?: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  path {
    fill: rgba(0, 0, 0, 1);
  }
`;

const Collapse = styled.div<{ isVisible: boolean }>`
  position: absolute;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  width: 100%;
  padding: 4px 0 4px 15px;
  top: calc(100% + 1px);
  left: 0;
  background-color: white;
  z-index: 3;
  border: 1px solid ${colors.gray70};
  border-radius: 5px;
`;
