import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { DocumentCardType } from '@/typings/model';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';

function DocumentCard({
  data,
  onClick,
}: {
  data: DocumentCardType;
  onClick?: () => void;
}) {
  return (
    <Component onClick={onClick}>
      <Content>
        <Wrapper>
          <StyledPicture
            {...convertThumbnailToPictureProps(data.file?.cover)}
          />
        </Wrapper>
        <Caption>{data.caption}</Caption>
      </Content>
    </Component>
  );
}

export default DocumentCard;

const Component = styled.div`
  cursor: pointer;
  margin-top: 12px;
  min-height: 250px;
`;
const Content = styled.div`
  padding: 5px 5px 10px;
  border: 1px solid ${colors.gray70};
`;
const Caption = styled.div`
  margin-top: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const StyledPicture = styled(Picture)`
  position: absolute;
  inset: 0;
  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;
const Wrapper = styled.div`
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  padding-top: min(83%, 210px);
`;
