import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { breakpoints } from '@/constants/theme';
import { useCurrentPage } from '@/hooks';
import { DefaultHomePageType } from '@/typings/model';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import Spinner from '@/components/Spinner';

SwiperCore.use([Navigation, Autoplay]);

function Slider() {
  const [mounted, setMounted] = useState(false);
  const page = useCurrentPage<DefaultHomePageType>();
  const { bannersMain } = page?.templateFields;

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Component>
      {!mounted ? (
        <Spinner />
      ) : (
        bannersMain && (
          <Swiper
            loop
            navigation
            slidesPerView={1}
            speed={500}
            autoplay={{ delay: 8000 }}
          >
            {bannersMain.map(
              ({ title, text, image, url, background }, index) => (
                <SwiperSlide key={(title ?? '') + index}>
                  <Content background={background}>
                    <StyledLink to={url}>
                      {image && (
                        <StyledPicture
                          {...convertThumbnailToPictureProps(image)}
                          alt={title}
                        />
                      )}
                    </StyledLink>
                    <Description>
                      <Title empty={!!image || background !== '#000000'}>
                        {title}
                      </Title>
                      {text && (
                        <SubTitle empty={!!image || background !== '#000000'}>
                          {text}
                        </SubTitle>
                      )}
                    </Description>
                  </Content>
                </SwiperSlide>
              )
            )}
          </Swiper>
        )
      )}
    </Component>
  );
}

const Component = styled.div`
  height: 53.03vh;
  min-height: 250px;

  position: relative;
  max-width: 1024px;
  margin: 0 auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media (orientation: landscape) {
    height: 80.03vh;
  }

  @media (min-width: ${breakpoints.mobileLarge}px) {
    height: 350px;
  }
  @media (min-width: ${breakpoints.tabletSmall}px) {
    height: 500px;
  }

  .swiper-container {
    height: 100%;
  }
`;

const Content = styled.div<{ background?: string }>`
  position: relative;
  height: 100%;
  background-color: ${({ background }) => background};
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledPicture = styled(Picture)`
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;

    @media (min-width: ${breakpoints.tabletSmall}px) {
    }
  }
`;

const Description = styled.div`
  padding: 0 15px;
  margin-top: 8%;
  position: absolute;
  inset: 0 0 0 0;
  text-align: center;

  @media (orientation: landscape) {
    //@media (min-width: 850px) {
    //  margin-top: 8%;
    //}
  }
`;

const Title = styled.div<{ empty: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;

  color: ${({ empty }) => (empty ? '#000' : '#fff')};

  @media (min-width: ${breakpoints.tabletSmall}px) {
    font-size: 26px;
  }
`;

const SubTitle = styled.div<{ empty: boolean }>`
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;

  @media (min-width: ${breakpoints.tabletSmall}px) {
    font-size: 22px;
  }
`;

export default Slider;
