import React from 'react';
import styled from 'styled-components';

import { useCurrentPage } from '@/hooks';
import BreadCrumb from '@/components/BreadCrumb';
import { BreadCrumbType } from '@/typings/common';
import ContentContainer from '@/components/ContentContainer';
import { DeliveryPageType } from '@/typings/model';

import Description from './components/Description';
import Map from './components/Map';
import Terms from './components/Terms';

function Dostavka() {
  const page = useCurrentPage<DeliveryPageType>();
  const { templateFields } = page;

  const breadCrumbs: BreadCrumbType[] = [];

  if (page) {
    breadCrumbs.push({
      label: page.title,
      url: page.path,
    });
  }

  return (
    <Component>
      <ContentContainer>
        <BreadCrumb breadCrumbs={breadCrumbs} />
      </ContentContainer>
      <Description
        title={page.title}
        body={page?.body ?? ''}
        data={templateFields?.items ?? []}
      />
      <Map title={templateFields?.mapTitle ?? ''} />
      <Terms
        title={templateFields?.textTitle ?? ''}
        content={templateFields?.textContent ?? ''}
      />
    </Component>
  );
}

export default Dostavka;

const Component = styled.div``;
