import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { useSettingItem } from '@/hooks';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  title: string;
  note: string;
  content: string;
};

function RassrochkaSection({ title, note, content }: Props) {
  const creditPrices = useSettingItem('PRICES_CREDIT');

  return (
    <Components>
      <ContentContainer>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Note>{note}</Note>
        <Content
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
        <Table>
          <Heading>Первоначальный взнос</Heading>
          <Heading>Срок рассрочки</Heading>
          <Heading>Переплата</Heading>
          {(creditPrices ?? []).map((item, index) => (
            <Row key={index}>
              <Item>{item.initial}%</Item>
              <Item>{item.period} мес.</Item>
              <Item>{item.underPayment}%</Item>
            </Row>
          ))}
        </Table>
      </ContentContainer>
    </Components>
  );
}

export default RassrochkaSection;

const Components = styled.div`
  margin-top: 35px;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;
const Note = styled.div`
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`;
const Content = styled.div`
  margin-top: 17px;

  & > p:not(:first-child) {
    margin-top: 20px;
  }
`;

const Table = styled.div`
  padding: 13px 20px 21px 15px;
  margin-top: 26px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border: 2px solid ${colors.primary};
`;
const Heading = styled.div`
  padding: 2px 4px;

  text-align: center;
  width: 33.33%;

  overflow-wrap: break-word;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.primary};

  ${media.mobileMedium(css`
    font-size: 11px;
  `)}
`;

const Item = styled.div`
  flex: 1 0 33.33%;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  ${media.mobileMedium(css`
    font-size: 14px;
  `)};
`;
const Row = styled.div`
  margin-top: 5px;
  display: flex;
  width: 100%;
`;
