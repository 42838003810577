import React, { useCallback, useEffect, useReducer, useState } from 'react';
import styled, { css } from 'styled-components';

import { ModalProps } from '@tager/web-components';
import { Nullable } from '@tager/web-core';

import { useSettingItem } from '@/hooks';
import ContentContainer from '@/components/ContentContainer';
import {
  ActionType,
  CalcAction,
  initStateKeys,
} from '@/modules/Rassrochka/components/Calculator/Clculator.typings';
import {
  getFinalPrice,
  getQuarterToString,
} from '@/modules/Rassrochka/components/Calculator/Calculator.helpers';
import { colors } from '@/constants/theme';

import CalculatorInput from './components/CalculatorInput';
import CalculatorSelect from './components/CalculatorSelect';
import Output from './components/Output';

const initOutputState = {
  price: 0,
  underpayment: 0,
  monthlyPayment: 0,
};

const initialState: Record<initStateKeys, number> = {
  periodSelected: 0,
  price: 1000,
  initPaymentRate: 25,
  rate: 0,
  initialPayment: getQuarterToString(1000, 25),
};

const calcReducer = (state: typeof initialState, action: CalcAction) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.SETPERIOD:
      return {
        ...state,
        periodSelected: payload,
      };
    case ActionType.SETPRICE:
      return {
        ...state,
        price: payload,
      };
    case ActionType.SETINITPAYMENTRATE:
      return {
        ...state,
        initPaymentRate: payload,
      };
    case ActionType.SETRATE:
      return {
        ...state,
        rate: payload,
      };
    case ActionType.SETINITIALPAYMENT:
      return {
        ...state,
        initialPayment: payload,
      };
    default:
      return state;
  }
};

function Calculator() {
  const [state, dispatch] = useReducer(calcReducer, initialState);
  const [outputData, setOutputData] = useState(initOutputState);

  const creditPrices = useSettingItem('PRICES_CREDIT');

  const setPeriodAndRate = useCallback(
    (periodIndex: number) => {
      const period = creditPrices[periodIndex].period;
      const rate = creditPrices[periodIndex].underPayment;
      if (period && Number(period) !== state.periodSelected) {
        dispatch({ type: ActionType.SETPERIOD, payload: Number(period) });
        dispatch({ type: ActionType.SETRATE, payload: Number(rate) });
      }
    },
    [creditPrices, state.periodSelected]
  );

  const getItemPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value.trim());
    dispatch({ type: ActionType.SETPRICE, payload: value });
  };

  const getFirstCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionType.SETINITIALPAYMENT,
      payload: Number(e.target.value.trim()),
    });
  };

  useEffect(() => {
    const { period, initial, underPayment } = creditPrices[0];
    if (period) {
      dispatch({ type: ActionType.SETPERIOD, payload: Number(period) });
    }
    if (initial) {
      dispatch({
        type: ActionType.SETINITPAYMENTRATE,
        payload: Number(initial),
      });
    }
    if (underPayment) {
      dispatch({ type: ActionType.SETRATE, payload: Number(underPayment) });
    }
  }, [creditPrices]);

  useEffect(() => {
    const data = getFinalPrice(
      state.price,
      state.rate,
      state.initialPayment,
      state.periodSelected
    );
    setOutputData(data);
  }, [state.price, state.rate, state.initialPayment, state.periodSelected]);

  return (
    <Component>
      <ContentContainer>
        <Title>Калькулятор рассрочки</Title>

        <Item>
          <Label>Стоимость товара, руб.</Label>
          <CalculatorInput
            value={state.price}
            icon="cart"
            onChange={getItemPrice}
          />
        </Item>
        <Item>
          <Label>Срок рассрочки</Label>
          <CalculatorSelect setData={setPeriodAndRate} data={creditPrices} />
        </Item>
        <Item>
          <Label>Первоначальный взнос (от 25%)</Label>
          <CalculatorInput
            value={state.initialPayment}
            icon="wallet"
            onChange={getFirstCost}
          />
        </Item>
        <Output output={outputData} />
      </ContentContainer>
    </Component>
  );
}

export default Calculator;

const Component = styled.div<{ addPadding?: boolean }>`
  background: ${colors.white};
  padding: 17px 0;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const Item = styled.div`
  margin-top: 19px;
  max-width: 480px;
`;
const Label = styled.div`
  margin-bottom: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
`;
