import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CartIcon } from '@/assets/svg/cart.svg';
import { ReactComponent as WalletIcon } from '@/assets/svg/wallet_small.svg';
import { colors } from '@/constants/theme';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  icon: 'cart' | 'wallet';
};

function CalculatorInput(
  { icon, ...rest }: Props,
  ref: React.Ref<HTMLInputElement>
) {
  const CurrentIcon = icon === 'cart' ? CartIcon : WalletIcon;

  return (
    <Container>
      <IconItem as={CurrentIcon} />

      <Input ref={ref} {...rest} />
    </Container>
  );
}

export default React.forwardRef<HTMLInputElement, Props>(CalculatorInput);

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 35px;
`;
const Input = styled.input`
  padding-left: 40px;
  width: 100%;
  height: 100%;
  border: 1px solid ${colors.gray70};
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
`;

const IconItem = styled.svg`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
`;
