import React from 'react';
import styled from 'styled-components';

import { useCurrentPage } from '@/hooks';
import { PaymentPageType } from '@/typings/model';
import ContentContainer from '@/components/ContentContainer';

function Erip() {
  const page = useCurrentPage<PaymentPageType>();
  const { templateFields } = page;

  return (
    <Component>
      <ContentContainer>
        <Title>{templateFields?.eripTitle}</Title>
        <Note>{templateFields?.eripNote}</Note>
        <Content
          dangerouslySetInnerHTML={{
            __html: templateFields?.eripContent ?? '',
          }}
        />
      </ContentContainer>
    </Component>
  );
}

export default Erip;

const Component = styled.div`
  margin-top: 35px;
`;
const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;
const Note = styled.div`
  margin-top: 6px;
`;
const Content = styled.div`
  margin-top: 18px;

  & > p,
  ul {
    margin-top: 18px;
  }
  & > ul li {
    margin-left: 15px;
    list-style: initial;
  }

  & > ul li:not(:first-child) {
    margin-top: 20px;
  }
`;
