import React from 'react';
import styled from 'styled-components';

import { useCurrentPage } from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';

function DefaultTemplate() {
  const page = useCurrentPage();

  return (
    <Container>
      <ContentContainer>
        <h1>{page?.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: page?.body ?? '' }} />
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  max-width: 1200px;
  margin: 20px auto;
`;

export default DefaultTemplate;
