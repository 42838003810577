import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import SwiperCore, { Navigation } from 'swiper';
import { useSelector } from 'react-redux';

import Link from '@/components/Link';
import ContentContainer from '@/components/ContentContainer';
import { breakpoints, colors } from '@/constants/theme';
import { useCurrentPage } from '@/hooks';
import { selectBlogPostListResource } from '@/store/reducers/tager/blog';
import PlainPostCard from '@/components/PlainPostCard';

SwiperCore.use([Navigation]);

function Blog() {
  const { templateFields } = useCurrentPage();
  const posts = useSelector(selectBlogPostListResource);

  return (
    <Component>
      <Header>
        <Title>{templateFields?.blogTitle}</Title>
        <Nav>
          <Prev className="prev-blog" />
          <Next className="next-blog" />
        </Nav>
      </Header>
      <ContentContainer>
        <Content>
          <Swiper
            navigation={{
              prevEl: '.prev-blog',
              nextEl: '.next-blog',
            }}
            slidesOffsetBefore={15}
            slidesOffsetAfter={15}
            spaceBetween={15}
            slidesPerView={1.25}
            loop
            slidesPerGroup={1}
            breakpoints={{
              576: {
                slidesPerView: 2.2,
              },
            }}
          >
            {posts.data.map((post) => (
              <SwiperSlide key={post.id}>
                <Link to={'/blog/' + post.urlAlias}>
                  <PlainPostCard post={post} />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </Content>
      </ContentContainer>
    </Component>
  );
}

export default Blog;

const Header = styled.div`
  padding: 0 15px;
  margin-bottom: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0 0 46px;
`;

const Prev = styled.div`
  cursor: pointer;
  position: relative;
  height: 22.5px;
  width: 15px;
  &:before {
    position: absolute;
    border-radius: 5px 0 0 5px;
    content: '';
    top: 0;
    right: 0;
    height: 1px;
    width: 16px;
    transform-origin: 100% 0;
    transform: rotate(315deg);
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:after {
    position: absolute;
    border-radius: 5px 0 0 5px;
    content: '';
    bottom: 0;
    right: 0;
    height: 1px;
    width: 15px;
    transform-origin: 100% 0;
    transform: rotate(45deg);
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:hover:before,
  &:hover:after {
    background-color: ${colors.black300};
  }
`;
const Next = styled.div`
  cursor: pointer;
  position: relative;
  height: 22.5px;
  width: 15px;

  &:before {
    cursor: pointer;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 1px;
    width: 16px;
    transform-origin: 0 0;
    transform: rotate(45deg);
    border-radius: 0 5px 5px 0;
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:after {
    cursor: pointer;
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 1px;
    width: 15px;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    border-radius: 0 5px 5px 0;

    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:hover:before,
  &:hover:after {
    background-color: ${colors.black300};
  }
`;
const Component = styled.div`
  margin-top: 17px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;
  @media (min-width: ${breakpoints.tabletSmall}px) {
    font-size: 24px;
  }
`;

const Content = styled.div`
  margin: 0 -15px;
`;
