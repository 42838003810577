import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  data?: string;
}

function Map({ title, data }: Props) {
  if (!data) {
    return (
      <Component>
        <Title>{title}</Title>
        <Content>Карта в разработке</Content>
      </Component>
    );
  } else {
    return <Component>{data}</Component>;
  }
}

export default Map;

const Component = styled.div`
  margin-top: 25px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
`;

const Content = styled.div`
  margin-top: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  background-color: rgba(224, 224, 224, 0.5);
`;
