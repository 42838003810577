import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { isBrowser } from '@tager/web-core';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { PaymentMethodCardType } from '@/typings/model';

function Item({ item }: { item: PaymentMethodCardType }) {
  const { button, title, image, description } = item;

  const clickHandler = () => {
    if (isBrowser()) {
      const domNode = document.querySelector(button?.link ?? '');
      const yOffset = -80;
      if (domNode) {
        window.scroll({
          top:
            domNode.getBoundingClientRect().top + window.pageYOffset + yOffset,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <Component>
      <StyledPicture
        {...convertThumbnailToPictureImage(image)}
        alt={title ?? ''}
      />
      <Description>
        <Title>{title}</Title>
        <Text>{description}</Text>
        {item.button && <Button onClick={clickHandler}>{button?.label}</Button>}
      </Description>
    </Component>
  );
}

export default Item;

const Component = styled.div`
  padding: 20px 15px;
  display: flex;
  align-items: flex-start;
`;

const StyledPicture = styled(Picture)`
  min-width: 70px;
`;

const Description = styled.div`
  margin-left: 16px;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;
const Text = styled.div`
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`;

const Button = styled.button`
  margin-top: 17px;
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 0;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  display: inline-block;
  width: 100px;
  background-color: ${colors.primary};
  color: #fff;
`;
