import React from 'react';
import styled from 'styled-components';

import BreadCrumb from '@/components/BreadCrumb';
import { useCurrentPage } from '@/hooks';
import { PaymentPageType } from '@/typings/model';
import { BreadCrumbType } from '@/typings/common';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import PaymentMethodsSection from '@/modules/Payment/components/PaymentMethodsSection';
import RassrochkaSection from '@/modules/Payment/components/RassrochkaSection';
import Erip from '@/modules/Payment/components/Erip';

import DocumentSection from './components/DocumentSection';
import Item from './components/Item';

function Payment() {
  const page = useCurrentPage<PaymentPageType>();

  const templateFields = page?.templateFields;

  const breadCrumb: BreadCrumbType[] = [];

  if (page) {
    breadCrumb.push({
      label: page.title,
      url: page.path,
    });
  }

  return (
    <Component>
      <Header>
        <ContentContainer>
          <BreadCrumb breadCrumbs={breadCrumb} />
        </ContentContainer>
      </Header>
      <Methods>
        <ContentContainer>
          <Title>{page.seoParams.title}</Title>
          <Text dangerouslySetInnerHTML={{ __html: page.body ?? '' }} />
        </ContentContainer>
        <ItemContainer>
          {(templateFields?.items ?? []).map((item, index) => (
            <Item item={item} key={item?.title ?? '' + index} />
          ))}
        </ItemContainer>
        <DocumentSection />
        <Wrapper id="cards">
          <PaymentMethodsSection />
        </Wrapper>
        <Wrapper id="rassrochka">
          <RassrochkaSection
            title={templateFields?.rassrochkaTitle ?? ''}
            note={templateFields?.rassrochkaNote ?? ''}
            content={templateFields?.rassrochkaContent ?? ''}
          />
        </Wrapper>
        <Wrapper id="erip">
          <Erip />
        </Wrapper>
      </Methods>
    </Component>
  );
}

export default Payment;

const Component = styled.div``;
const Header = styled.div``;
const Wrapper = styled.div``;
const Methods = styled.div``;
const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const Text = styled.div`
  margin-top: 10px;
`;

const ItemContainer = styled.div`
  margin-top: 25px;

  & > div {
    border-bottom: 1px solid ${colors.grayDivider};
  }

  & > div:first-child {
    border-top: 1px solid ${colors.grayDivider};
  }
`;
