import React from 'react';
import styled, { css } from 'styled-components';

import { MapFieldValueType, Nullish } from '@tager/web-core';
import {
  convertThumbnailToPictureImage,
  ThumbnailType,
} from '@tager/web-modules';

import BreadCrumb from '@/components/BreadCrumb';
import { useCurrentPage, useSettingItem } from '@/hooks';
import { colors } from '@/constants/theme';
import SocialMediaLink from '@/components/SocialMediaLink';
import { ReactComponent as MapPointerLogo } from '@/assets/svg/Footer/Map.point.icon/Map.point.logo.svg';
import { ReactComponent as Clock } from '@/assets/svg/clock.svg';
import ContentContainer from '@/components/ContentContainer';
import YandexMap from '@/components/YandexMap';
import Picture from '@/components/Picture';
import { BreadCrumbType } from '@/typings/common';

import Phones from './components/Phones';
import Email from './components/Email';

function getCoordinates(coordinates?: MapFieldValueType): Array<number> | null {
  if (!coordinates) return null;
  return Object.values(coordinates);
}

function Contacts() {
  const page = useCurrentPage();
  const a1 = useSettingItem('CONTACTS_PHONE_A1');
  const mts = useSettingItem('CONTACTS_PHONE_MTS');
  const email = useSettingItem('CONTACTS_EMAIL');

  const viber = useSettingItem('CONTACTS_VIBER');
  const whatsUp = useSettingItem('CONTACTS_WHATSAPP');
  const telegram = useSettingItem('CONTACTS_TELEGRAM');

  const pageFields = page?.templateFields;
  const coordinates = getCoordinates(pageFields?.map);
  const breadCrumbs: BreadCrumbType[] = [];

  if (page) {
    breadCrumbs.push({
      label: page.title,
      url: page.path,
    });
  }

  return (
    <Component>
      <ContentContainer>
        <PageHeader>
          <BreadCrumb breadCrumbs={breadCrumbs} />
        </PageHeader>
        <Content>
          <Title>{page.title}</Title>
          <Phones reference={{ a1, mts }} />
          <CallToAction>
            <ActionText>Звоните с 9:00 до 20:00, без выходных</ActionText>
          </CallToAction>
          {email && <Email reference={email} />}
          <SocialMediaWrapper>
            {viber && <SocialMediaLink type="Viber" reference={viber} />}
            {whatsUp && <SocialMediaLink type="WhatsUp" reference={whatsUp} />}
            {telegram && (
              <SocialMediaLink type="Telegram" reference={telegram} />
            )}
          </SocialMediaWrapper>
          <CallToAction>
            <ActionText>
              При заказе через мессенджеры <HighLight> скидка 2%</HighLight>
            </ActionText>
          </CallToAction>
          <MapWrapper>
            <MapTitle>{page?.templateFields?.mapTitle ?? ''}</MapTitle>
            <MapShopTitle>
              {page?.templateFields?.mapShopTitle ?? ''}
            </MapShopTitle>
            <Location>
              <StyledMapIcon />
              <Description>{page.templateFields?.mapAddress}</Description>
            </Location>
            <WorkTime>
              <StyledClock />
              <Description>{page.templateFields?.mapWorkTime}</Description>
            </WorkTime>
            <Map>
              <YandexMap
                center={coordinates}
                zoom={15}
                pins={coordinates ? [{ coordinates }] : undefined}
              />
            </Map>
            <MapHowTo>
              <Title>{pageFields?.mapHowToTitle}</Title>
              <MapHowToText
                dangerouslySetInnerHTML={{ __html: pageFields?.mapHowToText }}
              />
              {(pageFields?.gallery ?? []).map(
                (item: Nullish<ThumbnailType>, index: number) => (
                  <Picture
                    key={index}
                    {...convertThumbnailToPictureImage(item)}
                  />
                )
              )}
            </MapHowTo>
            <InvoiceDetails>
              <Title>{pageFields?.legalTitle}</Title>
              <PlainText
                dangerouslySetInnerHTML={{ __html: pageFields?.legalText }}
              />
            </InvoiceDetails>
          </MapWrapper>
        </Content>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.div``;
const PageHeader = styled.div``;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const Content = styled.div``;
const CallToAction = styled.div`
  margin-top: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  background: ${colors.gray237};
  border-radius: 5px;
`;
const ActionText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`;

const SocialMediaWrapper = styled.div`
  margin-top: 29px;

  display: flex;

  & > div:not(:last-child) {
    margin-right: 12px;
  }
`;

const HighLight = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;

  color: ${colors.darkRed};
`;

const mapDescriptionCss = css`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const flexStart = css`
  display: flex;
  align-items: flex-start;
`;

const MapWrapper = styled.div``;
const MapTitle = styled.div`
  margin-top: 37px;

  ${mapDescriptionCss}
`;
const MapShopTitle = styled.div`
  margin-top: 6px;

  ${mapDescriptionCss}
`;

const Location = styled.div`
  margin-top: 12px;

  & > *:first-child {
    margin-top: 5px;

    margin-right: 12px;
  }

  ${flexStart}
`;
const WorkTime = styled.div`
  margin-top: 15px;

  & > *:first-child {
    margin-right: 12px;
    margin-top: 5px;
  }

  ${flexStart}
`;
const Description = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
`;

const StyledMapIcon = styled(MapPointerLogo)`
  min-width: 16px;
  min-height: 16px;
`;
const StyledClock = styled(Clock)`
  min-width: 16px;
  min-height: 16px;
`;

const Map = styled.div`
  margin-top: 15px;
  position: relative;
  height: 320px;
`;

const MapHowTo = styled.div`
  margin-top: 22px;

  & > picture:first-child {
  }
`;
const MapHowToText = styled.div`
  margin-top: 9px;
`;
const InvoiceDetails = styled.div`
  margin-top: 25px;
`;
const PlainText = styled.div`
  margin-top: 7px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`;

export default Contacts;
