import React from 'react';
import styled from 'styled-components';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { PaymentPageType } from '@/typings/model';
import { useCurrentPage } from '@/hooks';
import { colors } from '@/constants/theme';

import MethodCard from './components/MethodCard';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

SwiperCore.use([Navigation]);

function PaymentMethodsSection() {
  const page = useCurrentPage<PaymentPageType>();
  const { templateFields } = page;

  return (
    <Component>
      <Header>
        <Title>{templateFields?.cardsTitle}</Title>
        <Nav>
          <Prev className="payment-methods-prev" />
          <Next className="payment-methods-next" />
        </Nav>
      </Header>
      <Text>{templateFields?.cardsText}</Text>
      <Content>
        {templateFields?.cardsItems && (
          <Swiper
            navigation={{
              prevEl: '.payment-methods-prev',
              nextEl: '.payment-methods-next',
            }}
            slidesOffsetBefore={15}
            slidesOffsetAfter={15}
            spaceBetween={15}
            slidesPerView={1.25}
            slidesPerGroup={1}
            breakpoints={{
              500: {
                slidesPerView: 2.2,
              },
            }}
          >
            {templateFields.cardsItems.map((card, index) => (
              <SwiperSlide key={index}>
                <MethodCard data={card} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Content>
    </Component>
  );
}

export default PaymentMethodsSection;

const Component = styled.div`
  margin-top: 20px;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;
const Prev = styled.div`
  cursor: pointer;
  position: relative;
  height: 22.5px;
  width: 15px;
  &:before {
    position: absolute;
    border-radius: 5px 0 0 5px;
    content: '';
    top: 0;
    right: 0;
    height: 1px;
    width: 16px;
    transform-origin: 100% 0;
    transform: rotate(315deg);
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:after {
    position: absolute;
    border-radius: 5px 0 0 5px;
    content: '';
    bottom: 0;
    right: 0;
    height: 1px;
    width: 15px;
    transform-origin: 100% 0;
    transform: rotate(45deg);
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:hover:before,
  &:hover:after {
    background-color: ${colors.black300};
  }
`;
const Next = styled.div`
  cursor: pointer;
  position: relative;
  height: 22.5px;
  width: 15px;

  &:before {
    cursor: pointer;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 1px;
    width: 16px;
    transform-origin: 0 0;
    transform: rotate(45deg);
    border-radius: 0 5px 5px 0;
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:after {
    cursor: pointer;
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 1px;
    width: 15px;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    border-radius: 0 5px 5px 0;

    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:hover:before,
  &:hover:after {
    background-color: ${colors.black300};
  }
`;

const Header = styled.div`
  padding: 0 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Nav = styled.div`
  margin-left: 50px;
  display: flex;
  justify-content: space-between;
  flex: 0 0 46px;
`;

const Text = styled.div`
  padding: 0 15px;

  margin-top: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
`;

const Content = styled.div``;
