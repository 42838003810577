import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { breakpoints } from '@/constants/theme';
import { BannersType } from '@/typings/model';

function CardItem({ title, image, text }: Partial<BannersType>) {
  return (
    <Component>
      <ContentContainer>
        <Description>
          <Title>{title}</Title>
          <SubTitle>{text}</SubTitle>
        </Description>
        <ImageContainer>
          <ImageBody>
            <Picture
              loading={'lazy'}
              {...convertThumbnailToPictureProps(image)}
              alt={title}
            />
          </ImageBody>
        </ImageContainer>
      </ContentContainer>
    </Component>
  );
}

export default CardItem;

const Component = styled.div`
  background-color: ${colors.grayCart};
  padding: 27px 0;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const ImageContainer = styled.div`
  display: inline-block;
  position: relative;
  
  picture {
    width: 100%;
    height: 100%;
  }
}

  img {
    height: 75px;
    object-fit: contain;
    

    @media (min-width: ${breakpoints.tabletSmall}px) {
     height: 90px;

    }
`;

const ImageBody = styled.div`
  & > div {
    width: 100%;
    height: 100%;
    justify-content: flex-end;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  @media (min-width: ${breakpoints.tabletSmall}px) {
    font-size: 24px;
  }
`;

const Description = styled.div`
  display: inline-block;
  flex: 0 1 55%;
`;

const SubTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  @media (min-width: ${breakpoints.tabletSmall}px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
