import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import { ReactComponent as Icon } from '@/assets/svg/mail.svg';

interface Props {
  reference: string;
}

function Email({ reference }: Props) {
  return (
    <Component>
      <Link to={`mailto:${reference}`}>
        <StyledIcon />
        <Title>{reference}</Title>
      </Link>
    </Component>
  );
}

export default Email;

const Component = styled.div`
  margin-top: 29px;

  & > a {
    display: flex;
    align-items: center;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 15px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
