import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

import 'swiper/swiper-bundle.min.css';

import 'swiper/swiper.min.css';

import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { topSellsTitle } from '@/constants/Pages/Home/topSellsTitle';
import ProductCard from '@/components/ProductCard';
import Link from '@/components/Link';
import { breakpoints, colors } from '@/constants/theme';
import { selectProductListByGroupResource } from '@/store/reducers/pages/products';
import { useTypedSelector } from '@/store/store';

SwiperCore.use([Navigation]);

function TopSells() {
  const { data } = useTypedSelector((state) =>
    selectProductListByGroupResource(state, 'TOP')
  );

  return (
    <Component>
      <Header>
        <Title>{topSellsTitle}</Title>
        <Nav>
          <Prev className="prev" />
          <Next className="next" />
        </Nav>
      </Header>
      <Body>
        <Content>
          <Swiper
            navigation={{
              prevEl: '.prev',
              nextEl: '.next',
            }}
            slidesOffsetBefore={15}
            slidesOffsetAfter={15}
            spaceBetween={15}
            slidesPerView={1.25}
            loop
            slidesPerGroup={1}
            breakpoints={{
              500: {
                slidesPerView: 2.2,
              },
            }}
          >
            {data?.map(
              (props) =>
                props?.image?.url && (
                  <SwiperSlide key={props.id}>
                    <Link to={'/products/' + props.urlAlias}>
                      <ProductCard {...props} />
                    </Link>
                  </SwiperSlide>
                )
            )}
          </Swiper>
        </Content>
      </Body>
    </Component>
  );
}
export default TopSells;

const Component = styled.div`
  margin-top: 17px;
`;
const Header = styled.div`
  padding: 0 15px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0 0 46px;
`;

const Prev = styled.div`
  cursor: pointer;
  position: relative;
  height: 22.5px;
  width: 15px;
  &:before {
    position: absolute;
    border-radius: 5px 0 0 5px;
    content: '';
    top: 0;
    right: 0;
    height: 1px;
    width: 16px;
    transform-origin: 100% 0;
    transform: rotate(315deg);
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:after {
    position: absolute;
    border-radius: 5px 0 0 5px;
    content: '';
    bottom: 0;
    right: 0;
    height: 1px;
    width: 15px;
    transform-origin: 100% 0;
    transform: rotate(45deg);
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:hover:before,
  &:hover:after {
    background-color: ${colors.black300};
  }
`;
const Next = styled.div`
  cursor: pointer;
  position: relative;
  height: 22.5px;
  width: 15px;

  &:before {
    cursor: pointer;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 1px;
    width: 16px;
    transform-origin: 0 0;
    transform: rotate(45deg);
    border-radius: 0 5px 5px 0;
    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:after {
    cursor: pointer;
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 1px;
    width: 15px;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    border-radius: 0 5px 5px 0;

    background-color: ${colors.black};
    transition: background-color 0.25s ease-in-out;
  }

  &:hover:before,
  &:hover:after {
    background-color: ${colors.black300};
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;

  @media (min-width: ${breakpoints.tabletSmall}px) {
    font-size: 24px;
  }
`;

const Body = styled.div``;
const Content = styled.div``;
