import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';

interface Props {
  title: string;
  content: string;
}

function Terms({ title, content }: Props) {
  return (
    <Component>
      <ContentContainer>
        <Title>{title}</Title>
        <Content dangerouslySetInnerHTML={{ __html: content ?? '' }} />
      </ContentContainer>
    </Component>
  );
}

export default Terms;

const Component = styled.div`
  margin-top: 20px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
`;
const Content = styled.div`
  margin-top: 15px;

  ul > li {
    list-style: initial;
    margin-left: 15px;
    margin-top: 30px;
  }

  ul > li:first-child {
    margin-top: 0;
  }
`;
