import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useCurrentPage } from '@/hooks';
import { DefaultHomePageType } from '@/typings/model';
import Link from '@/components/Link';
import CardItem from '@/modules/Home/components/Cards/CardItem';

function Cards() {
  const page = useCurrentPage<DefaultHomePageType>();
  const { bannersSidebar } = page?.templateFields;

  return (
    <Component>
      {bannersSidebar.map(({ title, image, text, url }, index) => (
        <Link to={url} key={index + (title ?? '')}>
          <CardItem image={image} title={title} text={text} />
        </Link>
      ))}
    </Component>
  );
}
export default Cards;

const Component = styled.div`
  width: 100%;
  min-height: 120px;
  padding-top: 12px;

  & > a:last-child {
    display: block;
    margin-top: 7px;
  }
`;
